.input-group {
    margin-bottom: 5px;
}

.input-group label {
    display: block;
    margin-bottom: 5px;
}

.input-group .required {
    color: red;
}

.input-group .error-message {
    color: #ff4c4c;
    text-align: left;
    font-size: 1.6rem;
    margin-top: 5px;
    display: block;
}
