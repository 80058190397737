* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    font-size: 62.5%;
}

.App {
    text-align: center;
    background-color: #121212;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.App-logo {
    height: 10vmin;
    width: auto;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    margin-bottom: 20px;
    background: transparent;
}

.App-header {
    margin-top: 60px;
}

@media (max-width: 600px) {
    .logout-button-container {
        top: 60px;
        right: 20px;
    }

    .App-logo {
        height: 15vmin;
    }
}

.App-link {
    color: #61dafb;
}

.logout-button-container {
    position: absolute;
    top: 10px;
    right: 10px;
}
