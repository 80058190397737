.navbar-collapse {
    display: flex;
    justify-content: flex-end;
}

.login-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-button {
    padding: 10px;
    font-size: 1.8rem;
}

.logout-button {
    padding: 10px;
    font-size: 1.8rem;
}
