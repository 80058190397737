h2 {
    margin-top: 20px;
}

h3 {
    border-bottom: 2px solid #e07a3f;
}

h2,
h3 {
    font-size: 2.5rem;
    color: #ffffff;
    text-transform: uppercase;
    padding-bottom: 5px;
    margin-bottom: 15px;
}

form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    min-width: 440px;
    max-width: 500px;
    margin: auto;
    background: #1e1e2e;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    background: linear-gradient(135deg, #1e1e2e, #121212);
}

.form-section {
    border-bottom: 1px solid #333;
    padding-bottom: 15px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

form label {
    font-weight: 500;
    font-size: 1.5rem;
}

form input,
form select {
    background-color: #1e1e2e;
    border: 1px solid #555;
    color: #ededed;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    max-width: 400px;
    transition: border-color 0.3s ease;
}

form input:-webkit-autofill,
form select:-webkit-autofill {
    -webkit-text-fill-color: #ededed;
    background-color: #1e1e2e !important;
    color: #ededed !important;
    -webkit-box-shadow: 0 0 0px 1000px #1e1e2e inset;
    box-shadow: 0 0 0px 1000px #1e1e2e inset;
    transition: background-color 5000s ease-in-out 0s;
}

form input:-webkit-autofill::first-line,
form select:-webkit-autofill::first-line {
    color: #ededed !important;
}

form input:-webkit-autofill:hover,
form select:-webkit-autofill:hover {
    color: #ededed !important;
    background-color: #1e1e2e !important;
}

.input-group {
    display: flex;
    flex-direction: column;
}

form label {
    font-size: 1.7rem;
    color: #aaaaaa;
    margin-bottom: 5px;
}

button,
.file-input-label {
    font-size: 1.8rem;
    background-color: #e07a3f;
    border: 1px solid #4a2d22;
    color: rgb(255, 255, 255);
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.file-input-label {
    margin-top: 10px;
}

button:hover,
.file-input-label:hover {
    background-color: #c56733;
}

.success-message {
    background-color: #4caf50;
    color: #ffffff;
    padding: 10px;
    margin-top: 15px;
    border-radius: 4px;
    text-align: center;
}

@media (max-width: 600px) {
    header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        margin-top: 60px;
    }

    h2 {
        font-size: 2.2rem;
        text-align: center;
    }

    h3 {
        font-size: 2.2rem;
    }

    form {
        min-width: 300px;
        width: 90%;
        padding: 15px;
    }

    form input,
    form select,
    button {
        max-width: 100%;
        font-size: 1.5rem;
    }

    .logo {
        width: 100%;
        text-align: center;
    }

    .logout-button {
        width: 100%;
        text-align: center;
    }
}
