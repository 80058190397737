.selected-files {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 150px;
    max-width: 400px;
    width: 100%;
    overflow-y: auto;
}

.selected-files ul {
    list-style-type: none;
    padding: 0;
    font-size: 1.5rem;
}

.file-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    background: #1e1e2e;
    color: #ededed;
    padding: 5px 10px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    overflow: hidden;
}

.file-name {
    flex-grow: 1;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 100px);
}

.delete-file {
    color: red;
    font-size: 1.6rem;
    margin-left: 10px;
    cursor: pointer;
}

@media (max-width: 600px) {
    .selected-files {
        max-width: 350px;
        width: 100%;
        overflow: hidden;
    }
}
